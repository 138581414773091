import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';

import { breakpoints, colors, fonts, spacing } from '../../utils/styles';
const ProductListingHeaderRoot = styled(`header`)`
  width: 100%;
`;

const Imagen = styled(`img`)`
  width: 100%;
  height: auto;
  @media (min-width: ${breakpoints.desktop}px) {
    font-size: 3rem;
  }
`;

const ProductListingSlider = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "fondo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
          resize(width: 400, jpegProgressive: true) {
            src
            width
          }
        }
      }
    }
  `);
  return (
    <ProductListingHeaderRoot>
      <Imagen src={data.placeholderImage.childImageSharp.fluid.src} />
    </ProductListingHeaderRoot>
  );
};

export default ProductListingSlider;
