import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from '@emotion/styled';

import ProductListingHeader from '../ProductListing/ProductListingHeader';
import ProductListingSlider from '../ProductListing/ProductListingSlider';
import ProductListingItem from '../ProductListing/ProductListingItem';

import { breakpoints, spacing } from '../../utils/styles';

const ProductListingContainer = styled(`div`)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spacing.lg}px;

  @media (min-width: ${breakpoints.desktop}px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: ${spacing['2xl']}px;
  }
`;

const ProductListing = () => (
  <StaticQuery
    query={graphql`
      query HomeListingQuery {
        products: shopifyCollection(title: { eq: "Home page" }) {
          title
          shopifyId
          products {
            id
            handle
            title
            description
            descriptionHtml
            productType
            variants {
              shopifyId
              title
              price
              availableForSale
            }
            images {
              id
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 910, maxHeight: 910) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ products }) => (
      <>
        <ProductListingSlider />
        <ProductListingContainer>
          {products.products.map(product => (
            <ProductListingItem key={product.id} product={product} />
          ))}
        </ProductListingContainer>
      </>
    )}
  />
);

export default ProductListing;
